import { React, useState, useEffect } from 'react'
import { Toast } from 'antd-mobile'
import { ExclamationShieldOutline, LeftOutline } from 'antd-mobile-icons'
import './Invite.css'
import _ from 'lodash'
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "qrcode.react";
export default function Invite() {
  const navigate = useNavigate();
  const [val, setVal] = useState('')
  const location = useLocation();
  useEffect(() => {
    let url = location.state.invite
    setVal(`${window.location.host}/#/Register?invite=${url}`)
  }, [])
  return (
    <div className='UserPay'>
      <div className='header'>
        <div className='backIcon' onClick={() => { navigate('/User') }}>
          <LeftOutline />
        </div>
        <p>邀请好友</p>
      </div>
      <div className='UserPay_box'>
        <div className='userPay_top'>
          <p className='i_text'>邀请码: {location.state.invite}</p>
        </div>
        <div className='qrcode'>
          <h3 className='invites_text'>邀请朋友，享受大额收益 <br /> 去邀请吧</h3>
          <div className='code'>
            <QRCode
              id={'qrCode'}
              value={val}  //value参数为生成二维码的链接
              size={180} //二维码的宽高尺寸
              fgColor="#000000"  //二维码的颜色
            />
          </div>
          <div className='payCode'>
            <p>{val}</p>
          </div>
          <div className='copy' onClick={() => {
            navigator.clipboard.writeText(val).then(() => {
              Toast.show({
                icon: 'success',
                content: '复制成功',
              })
            });
          }}>
            <p>复制</p>
          </div>
        </div>
      </div>
    </div>
  )
}
