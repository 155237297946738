export const zh = {
    //导航栏
    'n1': '首页',
    'n2': '订单',
    'n3': '交易',
    'n4': '团队',
    'n5': '我的',
    //提示类
    't1': '出错了',
    't2': '复制成功',
    't3': '请将信息填写完整',
    't4': '加载中…',
    't5': '登陆成功',
    't6': '评分需大于3才能提交',
    't7': '提交成功',
    't8': '余额不足，无法领取',
    't9': '提交失败',
    't10': '请先绑定地址,即将跳转个人中心页面',
    't11': '已绑定地址,请联系客服修改',
    't12': '修改成功',
    't13': '修改失败',
    't14': '请联系客服修改密码',
    't15': '密码格式错误',
    't16': '提现成功',
    't17': '最多可提现',
    't18': '最低提现',
    't19': '两次密码不一致',
    't20': '提示',
    't21': '暂时无法跳转',
    't22': '密码格式错误',
    't23': '注册成功',
    't24': '请输入区号',
    't25': '暂无更多',
    //登陆注册
    'l1': '语言',
    'l2': '请输入账号',
    'l3': '请输入密码',
    'l4': '记住',
    'l5': '登录',
    'l6': '去注册',
    'l7': '没有账号',
    'l8': '手机号',
    'l9': '昵称',
    'l10': '密码',
    'l11': '确认密码',
    'l12': '邀请码',
    'l13': '注册',
    'l14': '已有账号',
    'l15': '去登录',
    'l16': '请输入6-16位数字密码',

    //帮助
    'bz1': '帮助',
    'bz2': '常见问题',
    'bz3': '交易规则',
    'bz4': '如何提款',
    'bz5': '如何充值',
    'bz6': '1.Review RoBoT 是什么？',
    'bz7': 'Review RoBoT是一家营销推广公司，帮助世界各地的Review RoBoT网店商家获得更多的订单销售和增加Review RoBoT网店的浏览数据。我们致力于在Review RoBoT和商家以及消费者之间建立三方盈利的营销推广模式。结合最新的P2P区块链技术，通过USDT（TRC20，ERC20）快速连接消费者和商家。注册的用户可以获得订单的佣金，而商家则可以增加商店的销售数据。是互联网区块链模式中最新的盈利模式！',
    'bz8': '2.Review RoBoT 如何工作？',
    'bz9': '通过Review RoBoT的日常反馈显示，在Review RoBoT销售需要改进的产品，用户只需要轻松点击订单，系统自动生成订单订阅。用户通过区块链USDT支付订单金额，并获得每日佣金。',
    'bz10': '3.为什么两笔交易中同一货币的价格会有差异？',
    'bz11': '价格差异是由所有不利于货币自由流通的因素造成的，包括货币转移的速度、网络条件、货币准入限制、不同地区的人对货币的认可，甚至交易所提供的交易对类型、交易等。因此，同样的货币在不同的交易中可能会产生价格差异。',
    'bz12': '4.投资利润？',
    'bz13': '你得到的商品价格越高，你得到的订单利润就越高。同时，Review RoBoT随机分配大额佣金订单。',
    'bz14': '每天，系统自动生成并分发60个商品订单给用户，用户通过USDT完成每个订单的支付，获得0.6%的佣金。大额佣金订单是随机分配的。',
    'bz15': 'USDT在60个订单后可以提现。(如果60个订单没有完成，系统将自动停止到当天的剩余订单量)',
    'bz16': '每个账户只能绑定一个USDT账户，如果发生错误，请联系客服',
    'bz17': '完成每日订单交付后，可以正常提现，启动提现后24小时内到账，且无提现限制',
    'bz18': '充值前请绑定您的提现地址（支持TRC-20）USDT地址，您可以到APP或网页上点击充值，选择您使用的区块链（TRC-20）进行充值。',
    //模块文字
    //通用字
    'ty1': '确定',
    'ty2': '取消',
    'ty3': '提交',
    'ty4': '地址',
    //首页
    's1': '累计收益',
    's2': '服务',
    's3': '关于我们',
    's4': '帮助',
    's5': '合作伙伴',
    's6': '我的服务',
    //订单
    'd1': '未完成',
    'd2': '已完成',
    'd3': '暂无数据',
    'd4': '单号',
    'd5': '交易时间',
    'd6': '金额',
    'd7': '当前任务数',
    'd8': '预期收益',
    'd9': '还需',
    'd10': '评分',
    //交易
    'j1': '账户余额',
    'j2': '交易介绍',
    'j3': 'Review RoBoT 每天使用Review RoBoT的反馈来展示Review RoBoT销售的需要改进的产品。用户只需点击订单，系统就会自动生成订单签名。用户通过USDT区块链支付订单金额，每次可获得0.6%以上的佣金，系统随机分配大额奖励订单。',
    'j4': '开始匹配',
    'j5': '今日收益',
    'j6': '已完成数',
    'j7': '总任务数',
    //团队
    'td1': '余额',
    'td2': '佣金',
    'td3': '领取',
    'td4': '总人数',
    'td5': '用户',
    'td6': '贡献',
    'td7': '数量',
    'td8': '状态',
    //个人中心
    'g1': '提现',
    'g2': '充值',
    'g3': '邀请好友',
    'g4': '立即邀请',
    'g5': '好友注册后赚取的每笔利润，您都将获得相应比例的佣金',
    'g6': '个人信息',
    'g7': '资金明细',
    'g8': '充值记录',
    'g9': '提现记录',
    'g10': '语言',
    'g11': '退出登录',
    'g12': '确定退出登陆吗',
    //用户信息
    'yh1': '用户信息',
    'yh2': '修改',
    'yh3': '设置',
    'yh4': '电话',
    //明细
    'mx1': '佣金领取',
    'mx2': '收益',
    'mx3': '提现驳回',
    'mx4': '时间',
    'mx5': '充值成功',
    'mx6': '已拒绝',
    'mx7': '待处理',
    'mx8': '提现地址',
    'mx9': '可用余额',
    'mx10': '实际到账',
    'mx11': '请输入提款金额',
    //充值
    'cz1': '存款地址只支持ERC20,最低存款额为20USDT',
    'cz2': '复制',
    'cz3': '法定货币选择',
    'cz4': '支付',
    'cz5': '存款地址只支持TRC20-USDT，最低存款额为20USDT',
    'cz6': '存款地址只支持ERC20,最低存款额为20USDT',
    'cz1': '存款地址只支持ERC20,最低存款额为20USDT',
    'cz1': '存款地址只支持ERC20,最低存款额为20USDT',
    //区域
    'qy1': '区号',
}