import { React, useState, useEffect } from 'react'
import Tabber from '../copmonets/Tabber'
import { Toast } from 'antd-mobile'
import './team.css'
import _ from 'lodash'
import { getInfo, receiveBonus, getTeam } from '../request/api'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Team() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const throttle = _.throttle;
    const [table, setTable] = useState([])
    const [data, setData] = useState([])
    const getData = async () => {
        const res = await getInfo({})
        if (res.code == 0) {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('warning');
            localStorage.removeItem('tan');
            localStorage.removeItem('tabber');
            Toast.show({
                icon: 'fail',
                content: res.info,
            })
            setTimeout(() => {
                window.location.hash = "/login"
            }, 2500);
        }
        setData(res.data)

    }
    const getDatas = () => {
        if (data.bonus == '0.00') {
            Toast.show({
                content: t('t8'),
                duration: 1500,
            })
        } else {
            const res = receiveBonus({})
            if (res.code == 1) {
                Toast.show({
                    content: res.info,
                    duration: 1500,
                })
            } else {
                Toast.show({
                    content: 'error',
                    duration: 1500,
                })
            }
            setTimeout(() => {
                getData1()
                getData()
            }, 1500);
        }
    }
    const getData1 = async () => {
        const res = await getTeam({})
        setTable(res.data)
    }
    useEffect(() => {
        getData()
        getData1()
    }, [])
    return (
        <div className='team'>
            <div className='header'>{t('n4')}</div>
            <div className='team_box'>
                <div className='team_top'>
                    <div className='team_info'>
                        <div className='team_topNum'>
                            <p>{t('td1')}</p>
                            <div>
                                <p>$</p>
                                <p>{data.balances}</p>
                            </div>
                        </div>
                        <div className='team_topNum'>
                            <p>{t('td2')}</p>
                            <div>
                                <p>$</p>
                                <p>{data.bonus}</p>
                            </div>
                        </div>
                    </div>
                    <div className='team_get' onClick={getDatas}>
                        <p>{t('td3')}</p>
                    </div>
                </div>
                <div className='team_table'>
                    <div className="team_num">
                        <div>
                            <span>{t('td4')}</span>
                            <p>{table.length}</p>
                        </div>
                    </div>
                    <div className="team_list">
                        <div>
                            <p>{t('td5')}</p>
                            <p>{t('td6')}({t('td7')})</p>
                            <p>{t('td9')}</p>
                        </div>
                        {table.map((item, index) => {
                            return (
                                <div className="list_content" key={item.id}>
                                    <p>{item.nickname}</p>
                                    <p>{item.bonus}({item.task_num})</p>
                                    <p style={{ color: item.task_num < 60 ? 'red' : 'green' }}>{item.task_num < 60 ? t('d1') : t('d2')}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <Tabber></Tabber>
        </div>
    )
}
